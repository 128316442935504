<template>
  <v-card elevation="1" class="mt-0 py-lg-10" style="margin: 0 !important">
    <v-dialog v-model="changePasswordModal" max-width="800">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline d-flex justify-center">
          {{ $t('labels.changePassword') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <ValidationObserver ref="observer">
            <v-row>
              <FormElement
                v-for="element in formItems"
                :key="element.key.parent + '_' + element.key.child"
                :vertical="element.vertical"
                v-model="$data[element.key.parent][element.key.child]"
                :validationLabel="$t(element.validationLabel)"
                :rules="element.rules"
                :onChange="element.onChange"
                :formType="element.formType"
                :lg="element.lg"
                :cols="element.cols"
                :placeholder="$t(element.placeholder)"
              />
            </v-row>
          </ValidationObserver>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn
            variant="tonal"
            class="rounded-lg"
            style="
              color: white;
              text-transform: none;
              font-size: large;
              font-weight: bold;
              padding: 1em;
            "
            color="#0077d5"
            @click="changePassword"
          >
            {{ $t('buttons.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="usersModal" max-width="1000">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline d-flex justify-center">
          {{ $t('title.companyInformationDetail') }}
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <TabScreen
            v-if="usersModal"
            :changeIndexFunction="handleTabChange"
            :slots="[
              {
                name: 'informations',
                title: this.$t('navigationDrawerMenu.company'),
              },
              {
                name: 'files',
                title: this.$t('labels.filesHr'),
              },
              { name: 'users', title: this.$t('labels.user.plural') },
              { name: 'package', title: this.$t('labels.companyPackage') },
            ]"
          >
            <template v-slot:informations>
              <v-row style="padding-top: 3vh">
                <v-col class="d-flex justify-center" cols="12" lg="12">
                  <v-avatar size="150">
                    <v-img
                      class="my-2"
                      alt="COMPANY'S LOGO"
                      :lazy-src="getNoImage"
                      contain
                      :src="
                        activeItem.company.PhotoUrl
                          ? activeItem.company.PhotoUrl
                          : getNoImage
                      "
                    ></v-img>
                  </v-avatar>
                </v-col>
                <FormElement
                  key="COMPANY_NAME"
                  :vertical="true"
                  v-model="activeItem.company.CompanyName"
                  :validationLabel="$t('labels.companyName')"
                  :disabled="true"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="12"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.companyName')"
                />
                <FormElement
                  key="TAX_NO"
                  :vertical="true"
                  v-model="activeItem.company.TaxNo"
                  :validationLabel="$t('labels.company.taxNo')"
                  :disabled="true"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.company.taxNo')"
                />
                <FormElement
                  key="PHONE_NUMBER"
                  :vertical="true"
                  v-model="activeItem.company.GsmNo"
                  :validationLabel="$t('labels.phone')"
                  :disabled="true"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.phone')"
                />

                <FormElement
                  key="EMAIL"
                  :vertical="true"
                  v-model="activeItem.company.Email"
                  :validationLabel="$t('labels.email')"
                  :disabled="true"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.email')"
                />
                <FormElement
                  v-if="!activeItem.company.website"
                  key="WEBSITE"
                  :vertical="true"
                  v-model="activeItem.company.Website"
                  :validationLabel="$t('labels.website')"
                  :disabled="true"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.website')"
                />
                <FormElement
                  key="MAIN_ADDRESS"
                  :vertical="true"
                  v-model="activeItem.company.Address1"
                  :validationLabel="$t('labels.address')"
                  :disabled="true"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'textarea',
                  }"
                  :placeholder="$t('labels.address')"
                />
                <FormElement
                  key="RECEIPT_ADDRESS"
                  :vertical="true"
                  v-model="activeItem.company.ReceiptAddress"
                  :validationLabel="$t('labels.receiptAddress')"
                  :disabled="true"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'textarea',
                  }"
                  :placeholder="$t('labels.receiptAddress')"
                />
              </v-row>
            </template>

            <template v-slot:files>
              <v-data-table
                key="COMPANY_FILES"
                :headers="filesHeaders"
                :loading="$store.state.loadingStatus"
                :items="companyFiles"
                :no-data-text="$t('messages.noData')"
                hide-default-footer
              >
                <!-- eslint-disable -->
                <template v-slot:item.CreatedOnUtc="{ item }">
                  {{ convertToLocal(item.CreatedOnUtc) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex text-center justify-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="#29B7B7"
                          dark
                          class="button"
                          target="_blank"
                          :href="item.Url"
                        >
                          <v-icon
                            small
                            style="font-size: 20px"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-file-document-arrow-right-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('labels.tooltipInspectDocument') }}</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </template>
            <template v-slot:users>
              <v-data-table
                key="COMPANY_USERS"
                :headers="usersHeaders"
                :loading="$store.state.loadingStatus"
                :items="companyUsers"
                :no-data-text="$t('messages.noData')"
                hide-default-footer
              >
                <!-- <template v-slot:footer>
                  <div class="d-flex justify-center mt-8 pb-lg-0 pb-8">
                    <v-row justify="space-between">
                      <v-col md="2"></v-col>
                      <v-col md="8" class="ml-md-auto">
                        <v-pagination
                          v-if="getUserPageLength > 0"
                          class="my-4"
                          @input="onUsersPageChange"
                          :value="usersPage"
                          :length="getUserPageLength"
                          :total-visible="10"
                          circle
                        ></v-pagination>
                      </v-col>
                      <v-col md="2" class="ml-md-auto">
                        <v-select
                          v-model="usersPerPage"
                          :items="perPageValues"
                          :label="$t('labels.selectItemsPerPage')"
                          item-value="value"
                          item-text="name"
                          outlined
                          dense
                          @change="changeUsersPerPage"
                          rounded
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </div>
                </template> -->
                <template v-slot:item.FullName="{ item }">
                  {{ item.Name + ' ' + item.Surname }}
                </template>
                <template v-slot:item.ProfilePicture="{ item }">
                  <v-avatar v-if="false" class="my-2">
                    <v-img :src="item.PhotoUrl"></v-img>
                  </v-avatar>
                  <v-avatar v-else class="my-2">
                    <v-img src="../assets/no-image.png"></v-img>
                  </v-avatar>
                </template>
                <template v-slot:item.Role="{ item }">
                  {{ $t(`labels.roles.${item.Roles[0]}`) }}
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          color="#29B7B7"
                          class="mr-1"
                          style="font-size: 20px"
                          v-bind="attrs"
                          v-on="on"
                          @click="loginAs(item)"
                        >
                          mdi-login-variant
                        </v-icon>
                      </template>
                      <span>{{ $t('labels.loginAs') }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          color="#29B7B7"
                          class="mr-1"
                          style="font-size: 20px"
                          v-bind="attrs"
                          v-on="on"
                          @click="openChangePassword(item)"
                        >
                          mdi-lock-reset
                        </v-icon>
                      </template>
                      <span>{{ $t('labels.changePassword') }}</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </template>
            <template v-slot:package>
              <div class="d-flex justify-center">
                <FormElement
                  v-model="selectedPackage"
                  :validationLabel="$t('labels.companyPackage')"
                  :formLabel="$t('labels.companyPackage')"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: packages,
                      text: 'Name',
                      value: 'Value',
                    },
                  }"
                  :labelShown="false"
                  :cols="12"
                  :sm="10"
                  :md="10"
                  :lg="10"
                  :xl="10"
                  :topLabel="true"
                  :hideDetails="true"
                  :placeholder="$t('labels.companyPackage')"
                  :classes="'mt-4 mb-1 mb-sm-0'"
                />
              </div>
            </template>
          </TabScreen>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            v-if="selectedTab === 3"
            class="button"
            color="primary"
            @click="savePackage"
            >{{ $t('buttons.save') }}</v-btn
          >
          <v-btn
            class="button"
            @click="closeModal"
            color="var(--lightRed)"
            dark
            >{{ $t('buttons.close') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card-title style="color: var(--darkBlue)">
      <v-row align="center">
        <v-col class="text-lg-left text-center" lg="3" cols="12">
          <h5>{{ $t('navigationDrawerMenu.companies') }}</h5>
        </v-col>
        <v-spacer />
        <v-col class="text-end">
          <RefreshButton :refreshFunction="getCompanies"></RefreshButton>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <ConfirmationModal
      :title="$t('labels.warning')"
      :message="$t('messages.updateCompanyStatus')"
      :confirm="updateStatus"
      ref="confirmationModal"
      v-model="updateStatusModal"
    />
    <v-card-text>
      <v-row class="mt-4 mb-2 d-flex justify-start">
        <FormElement
          key="COMPANY_NAME_FILTER"
          v-model="companyName"
          :vertical="true"
          hide-details
          :validationLabel="$t('labels.companyName')"
          :topLabel="true"
          :labelShown="false"
          @input="filterContent()"
          :cols="6"
          :sm="4"
          :lg="3"
          :onChange="filterContent"
          :formType="{
            dense: true,
            name: 'text',
            appendIcon: 'mdi-magnify',
            appendClick: () => {
              filterContent();
            },
          }"
          :placeholder="$t('labels.companyName')"
        />
        <FormElement
          key="COMPANY_EMAIL_FILTER"
          v-model="email"
          :vertical="true"
          hide-details
          :validationLabel="$t('labels.email')"
          :topLabel="true"
          :labelShown="false"
          @input="filterContent()"
          :cols="6"
          :sm="4"
          :lg="3"
          :onChange="filterContent"
          :formType="{
            dense: true,
            name: 'text',
            appendIcon: 'mdi-magnify',
            appendClick: () => {
              filterContent();
            },
          }"
          :placeholder="$t('labels.email')"
        />
        <FormElement
          key="USERNAME_FILTER"
          v-model="username"
          :vertical="true"
          hide-details
          :validationLabel="$t('title.tableHeaderUsername')"
          :topLabel="true"
          :labelShown="false"
          @input="filterContent()"
          :cols="6"
          :sm="4"
          :lg="3"
          :onChange="filterContent"
          :formType="{
            dense: true,
            name: 'text',
            appendIcon: 'mdi-magnify',
            appendClick: () => {
              filterContent();
            },
          }"
          :placeholder="$t('title.tableHeaderUsername')"
          :classes="'mt-2 mt-sm-0'"
        />
        <FormElement
          key="PENDING_STATUS_FILTER"
          v-model="selectedPendingStatus"
          :vertical="true"
          hide-details
          :validationLabel="$t('labels.status')"
          :topLabel="true"
          :labelShown="false"
          :cols="6"
          :sm="4"
          :lg="3"
          :onChange="filterContent"
          :formType="{
            dense: true,
            name: 'multipleSelect',
            items: {
              data: pendingStatuses,
              text: 'name',
              value: 'value',
            },
          }"
          :placeholder="$t('labels.status')"
          :classes="'mt-2 mt-lg-0'"
        />
        <v-spacer />
        <v-col
          v-if="selectedPendingStatus || username || companyName || email"
          class="d-flex justify-end items-end"
        >
          <v-btn
            variant="tonal"
            color="#0177d4"
            class="button"
            style="color: white; border-radius: 14px !important"
            @click="
              () => {
                username = '';
                companyName = '';
                selectedPendingStatus = '';
                email = '';
                filterContent();
              }
            "
          >
            {{ $t('buttons.clearFilter') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers="companiesHeaders"
        hide-default-footer
        :loading="$store.state.loadingStatus"
        :options="{
          itemsPerPage: itemsPerPage,
        }"
        :items="users"
        :no-data-text="$t('messages.noData')"
      >
        <template v-slot:item.CountryCity="{ item }">
          {{ item.Country + ' / ' + item.City + ' / ' + item.District }}
        </template>
        <template v-slot:item.IsPortal="{ item }">
          {{ item.IsPortal ? '-' : 'Portal' }}
        </template>
        <template v-slot:item.CreatedOnUtc="{ item }">
          {{ item.CreatedOnUtc ? convertToLocal(item.CreatedOnUtc) : ''}}
        </template>
        <template v-slot:item.CompanyWorkType="{ item }">
          <v-chip
            v-if="item.CompanyWorkType == 1"
            style="font-weight: 600"
            class="ma-2"
            small
            color="#c5f4db"
            text-color="#2b3838"
          >
            {{ $t('labels.supplier') }}
          </v-chip>
          <v-chip
            v-if="item.CompanyWorkType == 2"
            style="font-weight: 600"
            class="ma-2"
            small
            color="#f4c5c5"
            text-color="#2b3838"
          >
            {{ $t('labels.customer') }}
          </v-chip>
          <v-chip
          v-if="item.CompanyWorkType == 3"
          style="font-weight: 600"
          class="ma-2"
          small
          color="#6BEDF4"
          text-color="#2b3838"
        >
          {{ $t('labels.portal') }}
        </v-chip>
        </template>
        <template v-slot:item.AccountType="{ item }">
          <v-chip
            v-if="item.AccountType == 0"
            style="font-weight: 600"
            class="ma-2"
            small
            color="#c5f4db"
            text-color="#2b3838"
          >
            {{ $t('labels.personal') }}
          </v-chip>
          <v-chip
            v-if="item.AccountType == 1"
            style="font-weight: 600"
            class="ma-2"
            small
            color="#f4c5c5"
            text-color="#2b3838"
          >
            {{ $t('labels.corporate') }}
          </v-chip>
        </template>

        <template v-slot:item.LastUserLoginDate="{ item }">
          {{
            item.LastUserLoginDate
              ? getFormattedDate(item.LastUserLoginDate)
              : '-'
          }}
        </template>
        <template v-slot:item.PendingStatus="{ item }">
          <v-chip
            v-if="item.PendingStatus == 0"
            style="font-weight: 600"
            class="ma-2"
            small
            color="#689fd5"
            text-color="#FFFFFF"
          >
            {{ $t('labels.waitingForApproval') }}
          </v-chip>
          <v-chip
            v-else-if="item.PendingStatus === 1 && item.CompanyLoginPermission"
            style="font-weight: 600"
            class="ma-2"
            small
            color="#c5f4db"
            text-color="#2b3838"
          >
            {{ $t('labels.approvedAccount') }}
          </v-chip>
          <v-chip
            v-else-if="item.PendingStatus === 2 || (item.PendingStatus === 1 && !item.CompanyLoginPermission)"
            style="font-weight: 600"
            class="ma-2"
            small
            color="#f4c5c5"
            text-color="#2b3838"
          >
            {{ $t(`labels.${item.PendingStatus === 2 ? 'rejected' : 'tooltipPassive'}`) }}
          </v-chip>
        </template>
        <template v-slot:footer>
          <div class="d-flex justify-center mt-8 pb-lg-0 pb-8">
            <v-row justify="space-between">
              <v-col md="2"></v-col>
              <v-col md="8" class="ml-md-auto">
                <v-pagination
                  v-if="pageLength > 0"
                  class="my-4"
                  @input="onPageChange"
                  :value="page"
                  :length="pageLength"
                  :total-visible="10"
                  circle
                ></v-pagination>
              </v-col>
              <v-col md="2" class="ml-md-auto">
                <v-select
                  v-model="itemsPerPage"
                  :items="perPageValues"
                  :label="$t('labels.selectItemsPerPage')"
                  item-value="value"
                  item-text="name"
                  outlined
                  dense
                  @change="changeItemsPerPage"
                  rounded
                >
                </v-select>
              </v-col>
            </v-row>
          </div>
        </template>

        <template v-slot:no-data>
          <div class="my-10 d-flex flex-column align-center">
            <h4>{{ $t('messages.noUser') }}</h4>
          </div>
        </template>
        <!-- eslint-disable -->

        <template v-slot:item.actions="{ item }">
          <v-tooltip
            top
            v-if="(item.PendingStatus === 1 && !item.CompanyLoginPermission) || item.PendingStatus === 0 || item.PendingStatus === 2"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="button mr-lg-1 mr-0 my-1"
                dark
                color="green"
                v-bind="attrs"
                v-on="on"
                @click="
                  updateStatusModal = true;
                  activeItem.Status = item.PendingStatus;
                  activeItem.PendingStatus = 1;
                  activeItem.CompanyId = item.Id;
                  activeItem.CompanyLoginPermission = item.CompanyLoginPermission;
                "
              >
                {{
                  $t(
                    `buttons.${item.PendingStatus === 0 ? 'approve' : 'activate'}`
                  )
                }}
              </v-btn>
            </template>
            <span>{{
              $t(
                `buttons.${item.PendingStatus === 0 ? 'approve' : 'activate'}`
              )
            }}</span>
          </v-tooltip>
          <v-tooltip
            top
           v-if="(item.PendingStatus === 1 && item.CompanyLoginPermission) || item.PendingStatus === 0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="button mx-md-1 mx-0 my-1"
                dark
                color="var(--lightRed)"
                v-bind="attrs"
                v-on="on"
                @click="
                  updateStatusModal = true;
                  activeItem.Status = item.PendingStatus;
                  activeItem.PendingStatus = 2;
                  activeItem.CompanyId = item.Id;
                  activeItem.CompanyLoginPermission = item.CompanyLoginPermission;
                "
              >
                {{ $t(`buttons.${item.PendingStatus === 0 ? 'reject' : 'blockUser'}`) }}
              </v-btn>
            </template>
            <span>{{ $t(`buttons.${item.PendingStatus === 0 ? 'reject' : 'blockUser'}`) }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                @click="getCompanyUsers(item)"
                style="cursor: pointer"
                class="ml-lg-1 ml-md-1 ml-0"
                dark
                v-bind="attrs"
                v-on="on"
                color="var(--turquoise)"
                >mdi-eye</v-icon
              >
            </template>
            <span>{{ $t('buttons.viewCompanyDetail') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import RefreshButton from '../components/RefreshButton.vue';
import { ITEMS_PER_PAGE_OPTIONS } from '../utils/constants';
import CreateOfferForm from './OfferScreens/CreateOfferForm.vue';
import FormElement from '../components/FormElement.vue';
import CompanyServices from '../services/CompanyServices';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import UserService from '../services/UserService';
import TabScreen from '../components/TabScreen.vue';
import AccountService from '../services/AccountService';
import CompanyPackageService from '../services/CompanyPackageService';
import { mapActions } from 'vuex';

import AdminService from '../services/AdminService'

export default {
  data() {
    return {
      showCreateOffer: false,
      openDeleteDialog: false,
      offerType: 1,
      selectedPendingStatus: '',
      pendingStatuses: [],

      selectedTab: 0,
      selectedPackage: null,
      packages: [],

      filesHeaders: [
        {
          text: this.$t('labels.fileName'),
          value: 'FileName',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.fileUploadDate'),
          value: 'CreatedOnUtc',
          sortable: false,
          groupable: false,
          align: 'center',
        },

        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
          groupable: false,
          align: 'center',
        },
      ],
      usersHeaders: [
        {
          text: this.$t('title.tableHeaderProfilePic'),
          value: 'ProfilePicture',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.fullName'),
          value: 'FullName',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.phone'),
          value: 'PhoneNumber',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.email'),
          value: 'Email',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.authority'),
          value: 'Role',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.actions'),
          value: 'actions',
          sortable: false,
          align: 'center',
        },
      ],
      companiesHeaders: [
        {
          text: this.$t('labels.companyName'),
          value: 'CompanyName',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.phone'),
          value: 'GsmNo',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.email'),
          value: 'Email',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.accountType'),
          value: 'CompanyWorkType',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.companyType'),
          value: 'AccountType',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.locationAsNoun'),
          value: 'CountryCity',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },

        {
          text: this.$t('labels.lastLogin'),
          value: 'LastUserLoginDate',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.company.taxNo'),
          value: 'TaxNo',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.company.taxOffice'),
          value: 'TaxOffice',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.averageWeeklyLoadCount'),
          value: 'AverageWeeklyUploadCount',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.averageWeeklyLoadWeight'),
          value: 'AverageWeeklyUploadWeight',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.authorizedPerson'),
          value: 'OwnerName',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderCreateDate'),
          value: 'CreatedOnUtc',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.status'),
          value: 'PendingStatus',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
      ],
      users: [],

      // States
      pageLoading: false,
      itemsPerPageOptions: [],
      selectedItems: [],
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
      showCreateOffer: false,
      deleteId: null,

      activeItem: {
        PendingStatus: 2,
        CompanyId: '',
        company: {},
      },
      updateStatusModal: false,
      usersModal: false,
      companyUsers: [],
      companyFiles: [],
      totalUsers: 0,
      usersPage: 1,
      usersPerPage: 10,

      username: '',
      companyName: '',
      email: '',
      changePasswordModal: false,
      changePasswordObject: {},

      item: {
        password: '',
        confirmPassword: '',
      },

      showPassword: false,
      showConfirmPassword: false,

      confirmation: {
        onConfirm: () => {},
        visible: false,
      },
    };
  },
  components: {
    CreateOfferForm,
    FormElement,
    RefreshButton,
    ConfirmationModal,
    TabScreen,
  },
  mounted() {
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.pendingStatuses = [
      {
        name: this.$t('labels.All'),
        value: '',
      },
      {
        name: this.$t('labels.waitingForApproval'),
        value: 0,
      },
      {
        name: this.$t('labels.approvedAccount'),
        value: 1,
      },
      {
        name: this.$t('labels.tooltipPassive'),
        value: 2,
      },
    ];
    this.setup();
  },
  computed: {
    formItems() {
      return [
        {
          key: {
            parent: 'item',
            child: 'password',
          },
          rules: 'required|max:24',
          rules: {
            required: true,
            max: 24,
            min: 6,
            regex:
              /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
          },
          validationLabel: 'labels.password',
          formLabel: 'labels.password',
          formType: {
            name: this.showPassword ? 'seePassword' : 'password',
            counter: '24',
            appendIcon: this.showPassword ? 'mdi-eye' : 'mdi-eye-off',
            appendClick: () => {
              this.showPassword = !this.showPassword;
            },
          },
          lg: 12,
          placeholder: 'labels.password',
        },
        {
          key: {
            parent: 'item',
            child: 'confirmPassword',
          },
          rules:
            'required|max:24|confirmedPassword:' + this.$t('labels.password'),
          validationLabel: 'labels.userPasswordAgain',
          formLabel: 'labels.userPasswordAgain',
          formType: {
            name: this.showConfirmPassword ? 'text' : 're-password',
            counter: '24',
            appendIcon: this.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',
            appendClick: () => {
              this.showConfirmPassword = !this.showConfirmPassword;
            },
          },
          lg: 12,
          placeholder: 'labels.userPasswordAgain',
        },
      ];
    },
    getNoImage() {
      return require('../assets/no-image.png');
    },
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    getUserPageLength() {
      return Math.ceil(this.totalUsers / this.itemsPerPage);
    },
  },
  watch: {
    usersModal(newValue) {
      if (!newValue) {
        this.activeItem.CompanyId = '';
      }
    },
  },
  methods: {
    changePassword() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          this.$store.state.loadingStatus = true;
          let body = {
            UserId: this.changePasswordObject.Id,
            NewPassword: this.item.password,
          };

          console.log(body);
          UserService.changePassword(body)
            .then((response) => {
              window.showSuccess(
                this.$t('description.changePasswordSuccessfully')
              );
              this.changePasswordModal = false;
            })
            .catch(window.showError)
            .finally(() => {
              this.$store.state.loadingStatus = false;
            });
        }
      });
    },
    openChangePassword(item) {
      this.changePasswordObject = item;
      this.changePasswordModal = true;
    },
    closeModal() {
      this.selectedPackage = null;
      this.usersModal = false;
    },
    savePackage() {
      this.$store.state.loadingStatus = true;
      let body = {
        CompanyId: this.activeItem.company.Id,
        CompanyPackageId: this.selectedPackage,
      };
      CompanyPackageService.setPackage(body)
        .then((response) => {})
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.closeModal();
          this.getCompanies();
        });
    },
    handleTabChange(index) {
      this.selectedTab = index;
      if (index === 3) {
        this.selectedPackage = this.activeItem.company.CompanyPackageId;
        this.getCompanyPackages();
      }
    },
    getCompanyPackages() {
      this.$store.state.loadingStatus = true;
      CompanyPackageService.getPackages(1, 99999)
        .then((response) => {
          this.packages = response.data.Result.map((item) => {
            return {
              Name: item.Name,
              Value: item.Id,
            };
          });
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    loginAs(item) {
      this.updateIsPrevAdmin(true);
      this.$store.state.loadingStatus = true;
      let body = {
        UserId: item.Id,
      };
      AccountService.adminLogin(body)
        .then((res) => {
          this.updatePortalUserId(this.$store.state.Id);
          this.portalLogin(res);
        })
        .catch((error) => {
          window.handleServiceError(error);
        })

        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.$router.push({ name: 'home' });
        });
    },
    filterContent() {
      setTimeout(() => {
        this.getCompanies();
      }, 300);
    },
    async getCompanyUsers(item, type = 'all') {
      this.$store.state.loadingStatus = true;
      this.activeItem.company = item;

      const companyId = this.activeItem.company.Id;

      if (type === 'files' || type === 'all') {
        const response = await CompanyServices.getCompanyFiles(
          1,
          10000,
          companyId
        ).finally(() => {
          if (type === 'files') {
            this.$store.state.loadingStatus = false;
          }
        });
        if (response) {
          this.companyFiles = response.data.Result;
        }
      }

      if (type === 'users' || type === 'all') {
        UserService.getCompanyUsers({
          pageSize: this.usersPerPage,
          page: this.usersPage,
          CompanyId: companyId,
        })
          .then((response) => {
            this.usersModal = true;
            this.companyUsers = response.data.Result.Users;
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    updateStatus() {
    const { Status, PendingStatus, CompanyId, CompanyLoginPermission } = this.activeItem; 
     
      
      this.updateStatusModal = true;
      this.$store.state.loadingStatus = true;
      let requestFunction = UserService.updateUserStatus, body = {
        CompanyId
      };

      if(Status === 1){
        requestFunction = AdminService.updateCompanyLoginPermission;
        body.CompanyLoginPermission = !CompanyLoginPermission;
      }
      else{
        body.PendingStatus = PendingStatus;
      }

      requestFunction(body)
        .then((response) => {
          window.showSuccess(this.$t('messages.updateUserStatusSuccessful'));
          this.getCompanies();
        })
        .catch(window.showError)
        .finally(() => {
          this.updateStatusModal = false;
          this.$store.state.loadingStatus = false;
          this.activeItem = {
            CompanyId: '',
            PendingStatus: 2,
          };
        });
    },
    changeItemsPerPage() {
      this.page = 1;
      this.getCompanies();
    },
    changeUsersPerPage() {
      this.usersPage = 1;
      this.getCompanyUsers(this.activeItem.company, 'users');
    },
    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return new Date(date).toLocaleString('tr-TR');
    },

    /**
     * Trigger when page changed.
     */
    onPageChange(newPage) {
      this.page = newPage;
      this.getCompanies();
    },
    onUsersPageChange(newPage) {
      this.usersPage = newPage;
      this.getCompanyUsers(this.activeItem.company, 'users');
    },
    setup() {
      this.getCompanies();
    },

    /**
     * Get search history from service.
     */
    getCompanies() {
      this.$store.state.loadingStatus = true;
      CompanyServices.getList({
        page: this.page,
        pageSize: this.itemsPerPage,
        PendingStatus: this.selectedPendingStatus,
        CompanyName: this.companyName,
        UserName: this.username,
        Email: this.email,
      })
        .then((response) => {
          this.users = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },

    /**
     * Reset all models in View.
     */
    resetAllModels() {
      this.resetForm();
    },

    ...mapActions(['updateIsPrevAdmin', 'portalLogin', 'updatePortalUserId']),
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}
.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}
</style>
