<template>
  <v-card elevation="1" class="mt-0 py-lg-10" style="margin: 0 !important">
    <v-dialog v-model="addChartEntryModal" @input="resetModal" max-width="800">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-sm-center popupHeadline" style="width: 100%">
            {{ $t('labels.addNew') }}
          </div>
        </v-card-title>

        <v-divider class="mb-4" />

        <ValidationObserver
          ref="addChartEntryObserver"
          v-slot="{ handleSubmit }"
        >
          <form autocomplete="off" @submit.prevent="handleSubmit(add)">
            <div style="position: absolute; top: 12px; right: 10px">
              <v-btn
                @click="handleDownload(1)"
                class="rounded-circle mr-2"
                style="
                  min-width: 1px;
                  min-height: 1px;
                  width: 36px;
                  height: 36px;
                  background-color: #0177d4;
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      color="white"
                      style="font-size: 20px"
                      v-on="on"
                    >
                      mdi-file-check-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.samplePriceExcelDownload') }}</span>
                </v-tooltip>
              </v-btn>
              <v-btn
                @click="handleDownload(2)"
                class="rounded-circle mr-2"
                style="
                  min-width: 1px;
                  min-height: 1px;
                  width: 36px;
                  height: 36px;
                  background-color: #0177d4;
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      color="white"
                      style="font-size: 20px"
                      v-on="on"
                    >
                      mdi-file-check-outline
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.sampleRegionExcelDownload') }}</span>
                </v-tooltip>
              </v-btn>
            </div>

            <v-card-text class="pb-0">
              <v-row>
                <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                  <input
                    type="radio"
                    id="one"
                    value="1"
                    style="
                      width: 20px;
                      height: 20px;
                      accent-color: var(--mainColor);
                      margin-right: 10px;
                    "
                    v-model="form.exportImportType"
                  />
                  <label
                    style="
                      display: inline-block;
                      padding: 5px 10px;
                      border-radius: 15px;
                      background-color: var(--mainColor);
                      color: white;
                      cursor: pointer;
                    "
                    class="custom-label-form-element"
                    for="one"
                    >{{ $t('labels.export') }}</label
                  >
                </v-col>
                <v-col cols="6" sm="3" md="3" lg="3" xl="3">
                  <input
                    type="radio"
                    id="two"
                    value="0"
                    style="
                      width: 20px;
                      height: 20px;
                      accent-color: var(--mainColor);
                      margin-right: 10px;
                    "
                    v-model="form.exportImportType"
                  />
                  <label
                    style="
                      display: inline-block;
                      padding: 5px 10px;
                      border-radius: 15px;

                      background-color: var(--mainColor);
                      color: white;
                      cursor: pointer;
                    "
                    class="custom-label-form-element"
                    for="one"
                    >{{ $t('labels.import') }}</label
                  >
                </v-col>

                <FormElement
                  key="ENTRY_NAME"
                  :vertical="true"
                  v-model="form.name"
                  :validationLabel="$t('labels.customerList')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.customerList')"
                />
                <FormElement
                  key="ENTRY_CARGO"
                  :vertical="true"
                  v-model="form.cargo"
                  :validationLabel="$t('labels.priceListName')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.priceListName')"
                />

                <FormElement
                  key="ENTRY_COUNTRY"
                  :vertical="true"
                  v-model="form.country"
                  :validationLabel="$t('labels.country')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: countries,
                      value: 'Id',
                      text: 'Name',
                    },
                  }"
                  :placeholder="$t('labels.country')"
                />

                <FormElement
                  key="ENTRY_CURRENCY"
                  :vertical="true"
                  v-model="form.currency"
                  :validationLabel="$t('labels.currency')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: currencies,
                      value: 'Code',
                      text: 'Code',
                    },
                  }"
                  :placeholder="$t('labels.currency')"
                />

                <FormElement
                  key="ENTRY_PORTAL_TYPE"
                  :vertical="true"
                  v-model="form.IntegrationLogistic"
                  :validationLabel="$t('titles.subsupplier')"
                  :topLabel="true"
                  rules="required"
                  :labelShown="false"
                  :onChange="portalTypeChanged"
                  :lg="6"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: portalTypes,
                      value: 'LogisticType',
                      text: 'LogisticName',
                    },
                  }"
                  :placeholder="$t('titles.subsupplier')"
                />

                <FormElement
                  key="ENTRY_PORTAL_TYPE_ID"
                  :vertical="true"
                  v-model="form.IntegrationLogisticId"
                  :validationLabel="$t('labels.accounts')"
                  :topLabel="true"
                  rules="required"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: accounts,
                      value: 'Id',
                      text: 'Name',
                    },
                  }"
                  :placeholder="$t('labels.accounts')"
                />

                <FormElement
                  key="ENTRY_INTEGRATION_SERVICE"
                  :vertical="true"
                  v-model="form.IntegrationService"
                  :validationLabel="$t('titles.integrationService')"
                  rules="required"
                  :active="form.IntegrationLogistic != null"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: integrationServices,
                      value: 'key',
                      text: 'value',
                    },
                  }"
                  :placeholder="$t('titles.integrationService')"
                />

                <FormElement
                  key="ENTRY_MAX_KG"
                  :vertical="true"
                  rules="required|double|min_value:0.01"
                  v-model="form.serviceMaxKg"
                  :validationLabel="$t('labels.maxKg')"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.maxKg')"
                />

                <FormElement
                  key="ENTRY_FSC"
                  :vertical="true"
                  rules="required|double"
                  v-model="form.fsc"
                  :validationLabel="$t('labels.fsc')"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.fsc')"
                />
                <FormElement
                  key="ENTRY_EXPIRY_DATE_START"
                  v-model="form.ValidityStartDate"
                  :validationLabel="$t('labels.expiryDateStart')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'date',
                    dateMode: 'date',
                  }"
                  :placeholder="$t('labels.expiryDateStart')"
                />
                <FormElement
                  key="ENTRY_EXPIRY_DATE_END"
                  v-model="form.ValidityEndDate"
                  :validationLabel="$t('labels.expiryDateEnd')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'date',
                    dateMode: 'date',
                  }"
                  :placeholder="$t('labels.expiryDateEnd')"
                />
                <FormElement
                  key="ENTRY_REFERENCE_NUMBER"
                  v-model="form.optionalReferenceID"
                  :validationLabel="$t('labels.optionalReferenceID')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'text',
                  }"
                  :placeholder="$t('labels.optionalReferenceID')"
                />
                <FormElement
                  key="ENTRY_PORTAL_USERS"
                  :vertical="true"
                  v-model="form.portalUserIds"
                  :validationLabel="$t('labels.portalUsers')"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: portalUsers,
                      value: 'Id',
                      text: 'CompanyName',
                      disableAutoSelection: true,
                    },
                    multiple: true,
                  }"
                  :placeholder="$t('labels.portalUsers')"
                />
                <FormElement
                  key="ENTRY_PRICES"
                  :vertical="true"
                  v-model="form.pricesFile"
                  :validationLabel="$t('labels.price')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :fileStyling="false"
                  :formType="{
                    name: 'file',
                    type: '',
                    data: {
                      accept:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                    },
                  }"
                  :placeholder="$t('labels.price')"
                />
                <FormElement
                  key="ENTRY_REGIONS"
                  :vertical="true"
                  v-model="form.regionsFile"
                  :validationLabel="$t('labels.region')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="6"
                  :fileStyling="false"
                  :formType="{
                    name: 'file',
                    type: '',
                    data: {
                      accept:
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
                    },
                  }"
                  :placeholder="$t('labels.region')"
                />
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-center pb-4">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;
                  padding: 2vh 2vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                :loading="$store.state.loadingStatus"
                >{{ $t('buttons.add') }}</v-btn
              >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 2vh 2vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="resetModal"
                >{{ $t('buttons.cancel') }}</v-btn
              >
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-card-title style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left text-center" lg="3" cols="12">
          <h5>{{ $t('navigationDrawerMenu.expressPriceList') }}</h5>
        </v-col>
      </v-row>

      <v-icon
        v-if="this.isSupplier"
        small
        class="mr-6"
        style="font-size: 32px"
        @click="addChartEntryModal = true"
      >
        mdi-plus-box-outline
      </v-icon>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-row class="mt-2" align="start">
        <FormElement
          v-for="(element, index, key) in filterFormItems"
          :key="element.key.parent + '_' + element.key.child"
          :vertical="element.vertical"
          v-model="$data[element.key.parent][element.key.child]"
          :validationLabel="$t(element.validationLabel)"
          :rules="element.rules"
          :formLabel="$t(element.formLabel)"
          :topLabel="element.topLabel"
          :labelShown="element.labelShown"
          :onChange="element.onChange"
          :md="element.md"
          :cols="element.cols"
          :sm="element.sm"
          :lg="element.lg"
          :xl="element.xl"
          :formType="element.formType"
          :placeholder="$t(element.placeholder)"
        />
      </v-row>
    </v-card-text>
    <v-data-table
      :headers="chartEntryHeaders"
      :items="items"
      hide-default-footer
      :loading="$store.state.loadingStatus"
      :options="{
        itemsPerPage: itemsPerPage,
      }"
      :item-key="'Id'"
      :no-data-text="$t('messages.noData')"
    >
      <template v-slot:footer>
        <div class="d-flex justify-center mt-8">
          <v-row justify="space-between">
            <v-col md="2"></v-col>
            <v-col md="8" class="ml-md-auto">
              <v-pagination
                v-if="pageLength > 0"
                class="my-4"
                @input="onPageChange"
                v-model="page"
                :length="pageLength"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="d-flex justify-center items-center pt-8 pr-6">
              <v-select
                v-model="itemsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                hide-details
                dense
                @change="changeItemsPerPage"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </template>
      <!-- eslint-disable -->
      <template v-slot:item.ExportImportType="{ item }">
        <v-chip
          v-if="item.ExportImportType == 0"
          style="font-weight: 600"
          class="ma-2"
          small
          color="#c5f4db"
          text-color="#2b3838"
        >
          {{ $t('labels.import') }}
        </v-chip>
        <v-chip
          v-if="item.ExportImportType == 1"
          style="font-weight: 600"
          class="ma-2"
          small
          color="#c5f4db"
          text-color="#2b3838"
        >
          {{ $t('labels.export') }}
        </v-chip>
      </template>
      <template v-slot:no-data>
        <div class="my-10 d-flex flex-column align-center">
          <h4>{{ $t('messages.noData') }}</h4>
        </div>
      </template>
      <!-- eslint-disable -->

      <template v-slot:item.isActive="{ item }">
        <v-chip
          class="ma-2"
          small
          text-color="white"
          :color="
            item.PriceListStatus === 0
              ? 'green'
              : item.PriceListStatus === 1
              ? 'red'
              : '#B2B2B2'
          "
        >
          {{
            $t(
              'labels.' +
                (item.PriceListStatus === 0
                  ? 'isActive'
                  : item.PriceListStatus === 1
                  ? 'tooltipPassive'
                  : 'expired')
            )
          }}
        </v-chip>
      </template>
      <template v-slot:item.ValidityStartDate="{ item }">
        {{ convertToLocal(item.ValidityStartDate) }}
      </template>
      <template v-slot:item.ValidityEndDate="{ item }">
        {{ convertToLocal(item.ValidityEndDate) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-start">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                color="#29B7B7"
                class="mr-1"
                style="font-size: 20px"
                v-bind="attrs"
                v-on="on"
                @click="
                  $router.push({ name: 'viewPriceList', params: { item } })
                "
              >
                mdi-arrow-right
              </v-icon>
            </template>
            <span>{{ $t('buttons.goToDetails') }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import ExpressCargoServices from '../services/ExpressCargoServices';
import IntegrationService from '../services/IntegrationService';
import PortalService from '../services/PortalService';
import {
  ITEMS_PER_PAGE_OPTIONS,
  getCountries,
  getCurrencyTypes,
  logisticsTypes,
} from '../utils/constants';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';

export default {
  data() {
    return {
      addChartEntryModal: false,
      regionFile: null,
      priceFile: null,
      currencies: [],
      portalTypes: [],
      portalUsers: [],
      integrationServices: [],
      integrationPackages: [],
      countries: [],
      chartEntryHeaders: [],
      items: [],
      accounts: [],

      // States
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
      addChartEntryModal: false,
      form: {
        name: '',
        cargo: '',
        country: '',
        currency: '',
        regionsFile: null,
        pricesFile: null,
        exportImportType: 1,
        expiryDate: new Date().toISOString().slice(0, 10),
        portalUserIds: [],
        IntegrationLogistic: null,
        IntegrationLogisticId: null,
        IntegrationService: null,
        serviceMaxKg: '',
        fsc: '',
      },

      isSupplier: false,

      filters: {
        searchText: '',
        subsupplier: null,
        exportImportType: null,
      },
      exportImportTypes: [
        {
          Value: 0,
          Name: this.$t('labels.import'),
        },
        {
          Value: 1,
          Name: this.$t('labels.export'),
        },
      ],
    };
  },
  components: { FormElement },
  mounted() {
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;

    this.isSupplier =
      this.$store.state.Type === 'Supplier' ||
      this.$store.state.Type === 'PendingSupplier';

    this.setup();
  },

  computed: {
    filterFormItems() {
      const callback = this.getHistory;

      return [
        {
          key: {
            parent: 'filters',
            child: 'searchText',
          },
          cols: 12,
          sm: 12,
          md: 3,
          lg: 3,
          xl: 3,

          validationLabel: 'labels.customer',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            appendIcon: 'mdi-magnify',
            appendClick: callback,
            items: {
              data: this.portalUsers,
              disableAutoSelection: true,
              value: 'CompanyName',
              text: 'CompanyName',
            },
          },
          onChange: () => {
            callback();
          },
          placeholder: 'labels.customer',
        },
        {
          key: {
            parent: 'filters',
            child: 'subsupplier',
          },
          cols: 12,
          sm: 12,
          md: 3,
          lg: 3,
          xl: 3,
          validationLabel: 'titles.subsupplier',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            appendIcon: 'mdi-magnify',
            appendClick: callback,
            items: {
              data: logisticsTypes,
              disableAutoSelection: true,
              value: 'Value',
              text: 'Name',
            },
          },
          onChange: () => {
            callback();
          },
          placeholder: 'titles.subsupplier',
        },
        {
          key: {
            parent: 'filters',
            child: 'exportImportType',
          },
          cols: 12,
          sm: 12,
          md: 3,
          lg: 3,
          xl: 3,
          validationLabel: 'labels.exportImportType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            appendIcon: 'mdi-magnify',
            appendClick: callback,
            items: {
              data: this.exportImportTypes,
              disableAutoSelection: true,
              value: 'Value',
              text: 'Name',
            },
          },
          onChange: () => {
            callback();
          },
          placeholder: 'labels.exportImportType',
        },
      ];
    },
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    handleDownload(option) {
      const downloadUrl1 =
        'https://algonder.s3.amazonaws.com/Documents/30.05.2024%2009:45:44Express-Fiyat-Listesi.xlsx?AWSAccessKeyId=AKIA5TWV25RSSAFBF64U&Expires=1748587545&Signature=I1JbaCrZHbimM%2BFN9Pa5rTfWYo0%3D';
      const downloadUrl2 =
        'https://algonder.s3.amazonaws.com/Documents/6/25/2024%2011:33:54%20AMExpress-B%C3%B6lge-Listesi.xlsx?AWSAccessKeyId=AKIA5TWV25RSSAFBF64U&Expires=1750840434&Signature=XOJ0AlqfBVtghPrjcdTxT8PpHu0%3D';

      const downloadUrl3 =
        'https://algonder.s3.amazonaws.com/Documents/19.06.2024%2020:23:10Ornek_Uzak_Bolge.xlsx?AWSAccessKeyId=AKIA5TWV25RSSAFBF64U&Expires=1750353791&Signature=1zQx1FRVntvbW8yUev23dJnakg4%3D';

      if (option === 1) {
        window.open(downloadUrl1, '_blank');
      } else if (option === 2) {
        window.open(downloadUrl2, '_blank');
      } else if (option === 3) {
        window.open(downloadUrl3, '_blank');
      }
    },
    uploadFile(file) {
      this.$store.state.loadingStatus = true;
      const formData = new FormData();
      formData.append('File', file);
      const service = this.isRegion
        ? ExpressCargoServices.updateRegionExcel
        : ExpressCargoServices.updatePriceExcel;

      service(formData)
        .then((response) => {
          console.log('Excel => ', response);
        })
        .catch(this.onServiceError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    add() {
      this.$store.state.loadingStatus = true;
      const formData = new FormData();
      formData.append('ValidityStartDate', this.form.ValidityStartDate);
      formData.append('ValidityEndDate', this.form.ValidityEndDate);
      formData.append('ListName', this.form.name);
      formData.append('CargoName', this.form.cargo);
      formData.append('OriginCountryId', this.form.country);
      formData.append('CurrencyCode', this.form.currency);
      formData.append('Prices', this.form.pricesFile);
      formData.append('Regions', this.form.regionsFile);
      if (this.form.portalUserIds && this.form.portalUserIds.length > 0) {
        this.form.portalUserIds.forEach((element) => {
          formData.append('PortalCompanyIds', element);
        });
      }
      formData.append('IntegrationLogistic', this.form.IntegrationLogistic);
      formData.append('IntegrationLogisticId', this.form.IntegrationLogisticId);
      formData.append('IntegrationService', this.form.IntegrationService);
      formData.append('ExportImportType', Number(this.form.exportImportType));
      formData.append('ServiceMaxKg', Number(this.form.serviceMaxKg));
      formData.append('FscRate', Number(this.form.fsc));

      formData.append('OptionalReferansId', this.form.optionalReferenceID);

      ExpressCargoServices.add(formData)
        .then((response) => {
          window.showSuccess(this.$t('messages.successfulAddExpress'));
          this.form = {};
          this.addChartEntryModal = false;
          this.getHistory();
          this.resetModal();
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return formatDate(date, '/');
    },

    changeItemsPerPage() {
      this.page = 1;
      this.getHistory();
    },

    /**
     * Trigger when page changed.
     */
    onPageChange() {
      this.getHistory();
    },

    async setup() {
      this.currencies = await getCurrencyTypes();
      this.countries = await getCountries();
      this.getHistory();
      this.getCompanyLogistics();
      this.getPortalUsers();
      this.chartEntryHeaders = [
        {
          text: this.$t('titles.subsupplier'),
          value: 'IntegrationLogistic',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.direction'),
          value: 'ExportImportType',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderCreateDate'),
          value: 'CreatedAt',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.currency'),
          value: 'CurrencyCode',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.expiryDateStart'),
          value: 'ValidityStartDate',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.expiryDateEnd'),
          value: 'ValidityEndDate',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.status'),
          value: 'isActive',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: this.$t('labels.tableActions'),
          value: 'actions',
          sortable: false,
          groupable: false,
        },
      ];

      if (this.isSupplier) {
        this.chartEntryHeaders.unshift({
          text: this.$t('labels.priceListName'),
          value: 'CargoName',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
        });
      } else {
        this.chartEntryHeaders.unshift({
          text: this.$t('labels.customerListName'),
          value: 'ListName',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
        });
      }
    },

    /**
     * Get search history from service.
     */
    getHistory() {
      this.$store.state.loadingStatus = true;
      ExpressCargoServices.getPriceList({
        page: this.page,
        pageSize: this.itemsPerPage,
        PortalUser: this.filters.searchText,
        GetForCustomer: !this.isSupplier,
        IntegrationLogistic: this.filters.subsupplier,
        ExportImportType: this.filters.exportImportType,
      })
        .then((response) => {
          this.items = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Get integrated logistic companies.
     */
    getCompanyLogistics() {
      this.$store.state.loadingStatus = true;
      IntegrationService.getCompanyLogistics()
        .then((response) => {
          this.portalTypes = response.data.Result;
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Get portal users for current supplier.
     */
    getPortalUsers() {
      this.$store.state.loadingStatus = true;
      PortalService.getPortalUsers()
        .then((response) => {
          this.portalUsers = response.data.Result.PortalCompanies;
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Triggers when portal type is changed.
     * After trigger two new fields are added respectively to the selected portal type
     */
    portalTypeChanged() {
      if (this.form.IntegrationLogistic) {
        this.getIntegrationServices(this.form.IntegrationLogistic);
        this.getIntegrationPackages(this.form.IntegrationLogistic);
        this.getAccounts(this.form.IntegrationLogistic);
      }
    },
    getAccounts(logisticId) {
      this.$store.state.loadingStatus = true;
      IntegrationService.getAccounts(logisticId)
        .then((response) => {
          this.accounts = response.data.Result;
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getIntegrationServices(type) {
      this.$store.state.loadingStatus = true;
      PortalService.getIntegrationServices(type)
        .then((response) => {
          this.integrationServices = response.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    getIntegrationPackages(type) {
      this.$store.state.loadingStatus = true;
      PortalService.getIntegrationPackages(type)
        .then((response) => {
          this.integrationPackages = response.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    resetModal() {
      this.$refs.addChartEntryObserver.reset();
      this.form = {
        name: '',
        cargo: '',
        country: '',
        currency: '',
        regionsFile: null,
        pricesFile: null,
        exportImportType: 1,
        expiryDate: new Date().toISOString().slice(0, 10),
      };
      this.addChartEntryModal = false;
    },

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}
.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}
</style>
