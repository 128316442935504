<template>
  <div>
    <v-card elevation="1" class="mt-0 py-lg-10" style="margin: 0 !important">
      <v-card-title class="popupHeadline">
        <v-row>
          <v-col class="text-lg-left text-center" lg="3" cols="12">
            <h5>{{ $t('navigationDrawerMenu.countryManagement') }}</h5>
          </v-col>
        </v-row>
        <RefreshButton :refreshFunction="getCountries" />
      </v-card-title>
      <v-divider />
      <v-row align="center" justify="start" class="d-flex pa-6">
        <FormElement
          v-for="(element, index) in formItems_filters"
          :key="
            element.key.parent + '_' + element.key.child + '_' + index
          "
          :willBeEliminated="false"
          v-model="$data[element.key.parent][element.key.child]"
          :validationLabel="$t(element.validationLabel)"
          :rules="element.rules"
          :formLabel="$t(element.formLabel)"
          :onChange="element.onChange"
          :formType="element.formType"
          :lg="element.lg"
          :md="element.md"
          :sm="element.sm"
          :xl="element.xl"
          :cols="element.cols"
          :hidden="element.hidden"
          :labelShown="false"
          :topLabel="true"
          :hideDetails="true"
          :active="element.active"
          :placeholder="$t(element.placeholder)"
          @input="getSearchDebounced"
        />
      </v-row>

      <v-data-table
        :headers="headers"
        :items="countriesData"
        :items-per-page="rowsPerPage"
        :page.sync="page"
        :loading="$store.state.loadingStatus"
        :no-data-text="$t('messages.noData')"
        hide-default-footer
        class="pa-3"
      >
        <template v-slot:item.IsBlocked="{ item }">
          <v-chip
            :color="item.IsBlocked ? '#e3e2e3' : '#c5f4db'"
            :text-color="'#2b3838'"
            class="ma-2"
            small
          >
            {{
              item.IsBlocked
                ? $t('labels.tooltipPassive')
                : $t('labels.tooltipActive')
            }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="'#29B7B7'"
                  style="font-size: 20px"
                  @click="toggleBlock(item)"
                >
                  {{
                    item.IsBlocked
                      ? 'mdi-arrow-up-bold-box-outline'
                      : 'mdi-block-helper'
                  }}
                </v-icon>
              </template>
              <span>{{
                item.IsBlocked ? $t('buttons.unBlock') : $t('buttons.block')
              }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>

      <div class="text-xs-center pt-2 md4 lg4">
        <v-row align="center">
          <v-col md="3" class="ml-md-auto">
            <v-pagination
              v-model="page"
              :length="totalPages"
              @input="getCountries"
              :total-visible="10"
              circle
            ></v-pagination>
          </v-col>
          <v-col md="2" class="ml-md-auto">
            <v-select
              rounded
              hide-details
              v-model="rowsPerPage"
              :items="perPageValues"
              item-value="value"
              item-text="name"
              :label="$t('labels.selectItemsPerPage')"
              dense
              outlined
              @change="changeItemsPerPage"
            >
            </v-select>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import FormElement from '../components/FormElement.vue';
import RefreshButton from '../components/RefreshButton.vue';
import CountryBlacklistService from '../services/CountryBlacklistService';

export default {
  data() {
    return {
      countriesData: [],
      debounceTimeout: null,
      headers: [
        {
          text: this.$t('labels.country'),
          value: 'CountryName',
        },
        {
          text: this.$t('labels.blockStatus'),
          value: 'IsBlocked',
          align: 'center',
        },
        { text: this.$t('labels.actions'), value: 'actions' },
      ],
      statuses: [
        {
          Name: this.$t('labels.All'),
          Value: null,
        },
        {
          Name: this.$t('labels.tooltipActive'),
          Value: false,
        },
        {
          Name: this.$t('labels.tooltipPassive'),
          Value: true,
        },
      ],
      rowsPerPage: 15,
      page: 1,
      totalItems: 0,
      perPageValues: [
        { name: '10', value: 10 },
        { name: '15', value: 15 },
        { name: '30', value: 30 },
        { name: '50', value: 50 },
        { name: '100', value: 100 },
      ],

      filters: {
        isBlocked: null,
        countryName: '',
      }
    };
  },
  computed: {
    formItems_filters(){
      return [
        {
          key: {
            parent: "filters",
            child: "countryName",
          },
          validationLabel: "labels.country",
          formLabel: "labels.country",
          formType: {
            name: "text",
            dense: true,
            onChange: this.filterContent,
          },
          cols:12,
          sm:6,
          md:4,
          lg:4,
          xl:4,
          placeholder: "labels.country",
        },
        {
          key: {
            parent: "filters",
            child: "isBlocked",
          },
          validationLabel: "labels.shipmentType",
          formLabel: "labels.shipmentType",
          formType: {
            name: "select",
            dense: true,
            outlined: true,
            items: {
              data: this.statuses,
              text: "Name",
              value: "Value",
            },
            onChange: this.filterContent,
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 4,
          xl: 4,
          placeholder: "labels.shipmentType",
        },
      ]
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.rowsPerPage);
    },
  },
  components: { RefreshButton, FormElement },
  mounted() {
    this.getCountries();
  },
  methods: {
    async getCountries() {
      this.$store.state.loadingStatus = true;
      try {
        const response = await CountryBlacklistService.getCountries(
          this.filters.isBlocked,
          this.filters.countryName,
          this.page,
          this.rowsPerPage
        );
        this.countriesData = response.data.Result;
        this.totalItems = response.data.TotalItems;
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.state.loadingStatus = false;
      }
    },
    async toggleBlock(item) {
      this.$store.state.loadingStatus = true;
      try {
        await CountryBlacklistService.update({
          CountryId: item.CountryId,
          IsBlocked: !item.IsBlocked,
        });
        this.getCountries();
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.state.loadingStatus = false;
      }
    },
    changeItemsPerPage() {
      this.page = 1;
      this.getCountries();
    },
    getSearchDebounced() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.rowsPerPage = 15;
        this.page = 1;
        this.getCountries();
      }, 300);
    },
    filterContent() {
      this.countriesData = [];
      this.rowsPerPage = 15;
      this.page = 1;
      this.getCountries();
    },
  },
};
</script>
