<template>
  <div>
    <ConfirmationModal
      :title="$t('labels.warning')"
      :message="$t('description.deleteModalDescription')"
      :confirm="deleteItem"
      v-model="deleteItemConfirmationModal"
    />
    <v-card elevation="1">
      <v-card-title style="color: var(--darkBlue)">
        <v-row>
          <v-col class="text-lg-left text-center" lg="5" cols="12">
            <h3>{{ $t('navigationDrawerMenu.additionalCosts') }}</h3>
          </v-col>
        </v-row>
        <v-icon
          v-if="
            $store.state.Type === 'Supplier' ||
            $store.state.Type === 'Accountant'
          "
          @click="
            costDialog = true;
            editFlag = false;
          "
          small
          class="mr-6"
          style="font-size: 32px"
        >
          mdi-plus-box-outline
        </v-icon>
        <RefreshButton :refreshFunction="getItems"></RefreshButton>
      </v-card-title>

      <v-divider />
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="10"
        :options="{
          itemsPerPage: itemsPerPage,
        }"
        style="padding: 0 16px"
        hide-default-footer
        :loading="$store.state.loadingStatus"
        :no-data-text="$t('messages.noData')"
        ><!-- eslint-disable -->
        <template v-slot:item.PhotoUrl="{ item }">
          <img
            :src="item.PhotoUrl"
            :alt="item.Title"
            style="width: 50px; height: 50px; object-fit: contain"
          />
        </template>
        <template v-slot:item.CreatedOnUtc="{ item }">
          {{ convertToLocal(item.CreatedOnUtc) }}
        </template>
        <template v-slot:item.Country="{ item }">
          {{ item.Country + ' ' + item.City }}
        </template>
        <template v-slot:item.LogisticType="{ item }">
          {{ item.LogisticType === 1 ? 'FedEx' : 'UPS' }}
        </template>
        <template v-slot:item.AdditionalCostType="{ item }">
          {{ additionalCostTypes[item.AdditionalCostType].name }}
        </template>
        <template v-slot:item.CostParameter="{ item }">
          {{ costParameters[item.CostParameter].name }}
        </template>
        <template v-slot:item.MinValue="{ item }">
          {{ item.MinValue ? item.MinValue : '-' }}
        </template>
        <template v-slot:item.PortalCustomerType="{ item }">
          <v-chip
            v-if="item.PortalCustomerType == 0"
            class="ma-2"
            small
            color="#c5f4db"
            text-color="#2b3838"
          >
            {{ $t('labels.tooltipCorporate') }}
          </v-chip>

          <v-chip
            v-if="item.PortalCustomerType == 1"
            class="ma-2 text--center"
            small
            color="#e3e2e3"
            text-color="#2b3838"
          >
            {{ $t('labels.tooltipMarketPlace') }}
          </v-chip>
        </template>
        <template v-slot:item.StatusType="{ item }">
          <v-chip
            v-if="item.StatusType == 1"
            class="ma-2"
            small
            color="#c5f4db"
            text-color="#2b3838"
          >
            {{ $t('labels.tooltipActive') }}
          </v-chip>

          <v-chip
            v-if="item.StatusType == 2"
            class="ma-2 text--center"
            small
            color="#e3e2e3"
            text-color="#2b3838"
          >
            {{ $t('labels.tooltipPassive') }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="#29B7B7"
                  class="mr-1"
                  style="font-size: 20px"
                  v-bind="attrs"
                  v-on="on"
                  @click="updateCost(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('labels.tooltipEdit') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="red"
                  style="font-size: 20px"
                  v-bind="attrs"
                  v-on="on"
                  @click="
                    deleteItemConfirmationModal = true;
                    selectedItem = item.Id;
                  "
                >
                  mdi-trash-can
                </v-icon>
              </template>
              <span>{{ $t('labels.deleteKey') }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>

      <v-dialog v-model="costDialog" @input="resetDialog" max-width="800">
        <v-card class="popup-card" elevation="1">
          <v-card-title
            class="popupHeadline d-flex justify-center"
            style="font-size: 16px"
          >
            {{ $t('labels.addAdditionalCost') }}
          </v-card-title>

          <v-divider></v-divider>
          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <v-card-text class="mt-2">
              <form @submit.prevent="handleSubmit(submitCost)">
                <v-row>
                  <FormElement
                    v-for="(element, index) in formElement"
                    :key="
                      element.key.parent + '_' + element.key.child + '_' + index
                    "
                    v-model="$data[element.key.parent][element.key.child]"
                    :validationLabel="$t(element.validationLabel)"
                    :rules="element.rules"
                    :formLabel="$t(element.formLabel)"
                    :onChange="element.onChange"
                    :formType="element.formType"
                    :lg="element.lg"
                    :cols="element.cols"
                    :hidden="element.hidden"
                    :labelShown="element.labelShown"
                    :topLabel="element.topLabel"
                    :active="element.active"
                    :placeholder="$t(element.placeholder)"
                  />
                </v-row>

                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: #0077d5;
                      padding: 2vh 3vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    type="submit"
                    color="primary"
                  >
                    {{
                      !editFlag ? $t('buttons.add') : $t('buttons.update')
                    }}</v-btn
                  >
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: var(--red);
                      padding: 2vh 2vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    @click="costDialog = false"
                    >{{ $t('buttons.cancel') }}</v-btn
                  >
                </v-card-actions>
              </form>
            </v-card-text>
          </ValidationObserver>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import RefreshButton from '../components/RefreshButton.vue';
import FormElement from '../components/FormElement.vue';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import AdditionalCostsServices from '../services/AdditionalCostsServices';
import { logisticsTypes, getPortalUsers } from '../utils/constants';

export default {
  data: () => ({
    users: [],
    form: {
      PaymetTime: 0,
    },
    costDialog: false,
    headers: [],
    itemsPerPageOptions: [],
    itemsPerPage: 10,
    page: 1,
    totalItems: 0,

    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],

    deleteItemConfirmationModal: false,
    selectedItem: '',
    list: [],

    additionalCostTypes: [],
    costParameters: [],
    editFlag: false,

    portalUsers: [],
  }),
  async mounted() {
    this.portalUsers = await getPortalUsers();

    this.additionalCostTypes = [
      {
        name: this.$t('labels.environmentalMeasure'),
        value: 0,
      },
      {
        name: this.$t('labels.ddp'),
        value: 1,
      },
      {
        name: this.$t('labels.additionalHandling'),
        value: 2,
      },
      {
        name: this.$t('labels.personalDelivery'),
        value: 3,
      },
      {
        name: this.$t('labels.oversize'),
        value: 4,
      },
      {
        name: this.$t('labels.insurance'),
        value: 5,
      },
      {
        name: this.$t('labels.signedDelivery'),
        value: 6,
      },
    ];
    this.costParameters = [
      {
        name: this.$t('labels.fixedPrice'),
        value: 0,
      },
      {
        name: this.$t('labels.perOrder'),
        value: 1,
      },
      {
        name: this.$t('labels.perPackage'),
        value: 2,
      },
      {
        name: this.$t('labels.perWeight'),
        value: 3,
      },
      {
        name: this.$t('labels.perProductPrice'),
        value: 4,
      },
    ];

    this.headers = [
      {
        text: this.$t('titles.integration.logisticsCompany'),
        value: 'LogisticType',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.additionalCostType'),
        value: 'AdditionalCostType',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.costParameter'),
        value: 'CostParameter',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.value'),
        value: 'Value',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.minValue'),
        value: 'MinValue',
        sortable: false,
        align: 'center',
      },
    ];
    if (
      this.$store.state.Type === 'Supplier' ||
      this.$store.state.Type === 'Accountant'
    ) {
      this.headers.push({
        text: this.$t('labels.actions'),
        value: 'actions',
        sortable: false,
      });
    }
    this.getItems();
  },
  watch: {
    costDialog(newValue) {
      if (!newValue) {
        this.resetDialog();
      }
    },
  },
  components: {
    RefreshButton,
    FormElement,
    ConfirmationModal,
  },
  computed: {
    formElement() {
      return [
        {
          key: {
            parent: 'form',
            child: 'LogisticType',
          },
          rules: 'required',
          validationLabel: 'titles.subsupplier',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: logisticsTypes,
              value: 'Value',
              text: 'Name',
            },
          },
          lg: 6,
          placeholder: 'titles.subsupplier',
        },
        {
          key: {
            parent: 'form',
            child: 'AdditionalCostType',
          },
          rules: 'required',
          validationLabel: 'labels.additionalCostType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.additionalCostTypes,
              value: 'value',
              text: 'name',
            },
          },
          lg: 6,
          placeholder: 'labels.additionalCostType',
        },
        {
          key: {
            parent: 'form',
            child: 'CostParameter',
          },
          rules: 'required',
          validationLabel: 'labels.costParameter',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.costParameters,
              value: 'value',
              text: 'name',
            },
          },
          lg: 6,
          placeholder: 'labels.costParameter',
        },
        {
          key: {
            parent: 'form',
            child: 'Value',
          },
          rules: 'required',
          validationLabel: 'labels.value',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          placeholder: 'labels.value',
        },
        {
          key: {
            parent: 'form',
            child: 'MinValue',
          },
          rules: 'numeric',
          validationLabel: 'labels.minValue',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          placeholder: 'labels.minValue',
        },
        {
          key: {
            parent: 'form',
            child: 'PortalIds',
          },
          validationLabel: 'labels.portalUsers',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.portalUsers,
              value: 'Id',
              text: 'CompanyName',
            },
            multiple: true,
          },
          lg: 6,
          placeholder: 'labels.portalUsers',
        },
      ];
    },
    pageLength() {
      //   return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    getItems() {
      this.$store.state.loadingStatus = true;
      AdditionalCostsServices.get()
        .then((response) => {
          this.list = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    resetDialog() {
      this.$refs.observer.reset();
      this.form = {};
    },

    deleteItem() {
      if (this.selectedItem) {
        this.$store.state.loadingStatus = true;
        AdditionalCostsServices.delete(this.selectedItem)
          .then((response) => {
            window.showSuccess(
              this.$t('messages.successfulDelete', {
                value: this.$t('labels.user.singular'),
              })
            );
            this.getItems();
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    submitCost() {
      let body = {
        LogisticType: this.form.LogisticType,
        AdditionalCostType: this.form.AdditionalCostType,
        CostParameter: this.form.CostParameter,
        Value: this.form.Value,
        MinValue: this.form.MinValue || null,
        PortalIds: this.form.PortalIds,
      };

      this.$store.state.loadingStatus = true;
      if (!this.editFlag) {
        AdditionalCostsServices.add(body)
          .then((response) => {
            this.getItems();
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
            this.costDialog = false;
          });
      } else {
        console.log('FORM >> ', this.form);
        body.Id = this.form.Id;
        AdditionalCostsServices.update(body)
          .then((response) => {
            this.getItems();
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
            this.costDialog = false;
          });
      }
    },
    updateCost(item) {
      console.log('ITEM >> ', item);
      this.form = item;
      this.costDialog = true;
      this.editFlag = true;
      // this.form.LogisticType = item.LogisticType;
      // this.form.AdditionalCostType = item.AdditionalCostType;
      // this.form.
    },
  },
};
</script>
