<template>
  <div>
    <v-card elevation="1">
      <v-card-title style="color: var(--darkBlue)">
        <v-row>
          <v-col class="text-lg-left text-center" lg="5" cols="12">
            <h3>{{ $t('labels.myAddresses') }}</h3>
          </v-col>
        </v-row>
        <div>
          <v-btn
            @click="dialog = true"
            class="rounded-circle mr-4"
            style="
              min-width: 1px;
              min-height: 1px;
              width: 36px;
              height: 36px;
              background-color: #0177d4;
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon small color="white" style="font-size: 24px" v-on="on">
                  mdi-plus
                </v-icon>
              </template>
              <span>{{ $t('labels.addAddress') }}</span>
            </v-tooltip>
          </v-btn>

          <RefreshButton :refreshFunction="getSubCompanies"></RefreshButton>
        </div>
      </v-card-title>

      <v-divider />

      <delete-modal :yesFunc="confirmDelete" ref="deleteModalRef" />

      <v-data-table
        :headers="headers"
        :items="subCompanies"
        :items-per-page="10"
        :options="{
          itemsPerPage: itemsPerPage,
        }"
        style="padding: 0 16px"
        hide-default-footer
        :loading="$store.state.loadingStatus"
        :no-data-text="$t('messages.noData')"
      >
        <template v-slot:footer>
          <div class="d-flex justify-center mt-8 pb-lg-0 pb-8">
            <v-row justify="center">
              <v-col md="3" class="ml-md-auto">
                <v-pagination
                  class="mr-2"
                  @input="onPageChange"
                  :value="page"
                  :length="pageLength"
                  :total-visible="15"
                  circle
                ></v-pagination>
              </v-col>
              <v-col md="2" class="ml-md-auto">
                <v-select
                  v-model="itemsPerPage"
                  :items="perPageValues"
                  :label="$t('labels.selectItemsPerPage')"
                  item-value="value"
                  item-text="name"
                  outlined
                  dense
                  @change="changeItemsPerPage()"
                  rounded
                >
                </v-select>
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:top> </template>
        <!-- eslint-disable -->
        <template v-slot:item.actions="{ item }">
          <div class="text-start">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="#29B7B7"
                  class="mr-1"
                  style="font-size: 20px"
                  v-bind="attrs"
                  v-on="on"
                  @click="editAddress(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('labels.tooltipEdit') }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="red"
                  class="mr-1"
                  style="font-size: 20px"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t('labels.tooltipDelete') }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>

      <v-dialog
        v-model="dialog"
        @input="resetDialog"
        @close="resetDialog"
        max-width="600"
      >
        <v-card class="popup-card" elevation="1">
          <v-card-title class="popupHeadline" style="font-size: 22px">
            <div class="text-center popupHeadline" style="width: 100%">
              {{
                isEditing
                  ? $t('labels.editSubCompany')
                  : $t('labels.addSubCompany')
              }}
            </div>
          </v-card-title>
          <v-divider class="mb-4" />
          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <v-card-text class="">
              <form @submit.prevent="handleSubmit(saveSubCompany)">
                <v-row>
                  <FormElement
                    v-for="(element, index) in formElement"
                    :key="
                      element.key.parent + '_' + element.key.child + '_' + index
                    "
                    v-model="$data[element.key.parent][element.key.child]"
                    :validationLabel="$t(element.validationLabel)"
                    :rules="element.rules"
                    :formLabel="$t(element.formLabel)"
                    :onChange="element.onChange"
                    :formType="element.formType"
                    :lg="element.lg"
                    :cols="element.cols"
                    :hidden="element.hidden"
                    :labelShown="element.labelShown"
                    :topLabel="element.topLabel"
                    :active="element.active"
                    :placeholder="$t(element.placeholder)"
                    @openURL="handleOpenURL"
                  />
                </v-row>
                <v-row class="mb-4">
                  <v-col
                    cols="12"
                    class="d-flex flex-row px-2"
                    style="padding: 0"
                  >
                    <v-checkbox
                      style="padding: 0; margin: 0"
                      hide-details
                      v-model="isHome"
                    ></v-checkbox>
                    <label> {{ $t('labels.isHome') }} </label>
                  </v-col>
                </v-row>
                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: #0077d5;
                      padding: 1vh 1vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    type="submit"
                    color="primary"
                  >
                    {{ $t('buttons.save') }}
                  </v-btn>

                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: var(--red);
                      padding: 1vh 1vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    @click="resetDialog"
                  >
                    {{ $t('buttons.cancel') }}
                  </v-btn>
                </v-card-actions>
              </form>
            </v-card-text>
          </ValidationObserver>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import CompanyServices from '../services/CompanyServices';
import RefreshButton from '../components/RefreshButton.vue';
import DeleteModal from '../components/General/DeleteModal';
import FormElement from '../components/FormElement.vue';
import AddressService from '../services/AddressService';
import { clearText } from '../utils/constants';
import rules, { authorizedUser, company } from '../utils/validationRules';

const USA_ID = 'ccf580f9-25e0-4aac-9216-6741eeece89e';
const CANADA_ID = '8e1060f5-9433-4b64-9e8f-3da864f7cac6';

const stateRequiredCountries = [USA_ID, CANADA_ID];

export default {
  data: () => ({
    showDeleteConfirmation: false,
    itemToDelete: null,
    form: {},
    subCompanies: [],
    countries: [],
    cities: [],
    headers: [],
    dialog: false,
    isEditing: null,
    itemsPerPageOptions: [],
    itemsPerPage: 10,
    page: 1,
    totalItems: 0,
    phonePrefix: null,
    isHome: false,
    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
  }),
  mounted() {
    this.perPageValues.push({
      name: this.$t('labels.All'),
      value: 9999,
    });
    this.headers = [
      { text: this.$t('labels.name'), value: 'Name' },
      { text: this.$t('labels.address'), value: 'Address' },
      {
        text: this.$t('labels.actions'),
        value: 'actions',
        sortable: false,
      },
    ];

    this.getSubCompanies();
    this.getCountries();
  },
  components: {
    RefreshButton,
    DeleteModal,
    FormElement,
  },
  computed: {
    isStateCodeRequired() {
      if (!this.form.countryCode) return false;

      return (
        stateRequiredCountries.findIndex(
          (element) => element === this.form.countryCode
        ) > -1
      );
    },
    formElement() {
      return [
        {
          key: {
            parent: 'form',
            child: 'companyName',
          },
          rules: company,
          validationLabel: 'labels.companyName',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          placeholder: 'labels.companyName',
        },
        {
          key: {
            parent: 'form',
            child: 'companyAddress',
          },
          rules: 'required',
          validationLabel: 'labels.address1',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
            textTransform: 'uppercase',
          },
          cols: 12,
          placeholder: 'labels.address1',
        },
        {
          key: {
            parent: 'form',
            child: 'countryCode',
          },
          rules: 'required',
          validationLabel: 'labels.country',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.countries,
              value: 'Id',
              text: 'Name',
            },
          },
          onChange: () => {
            this.onChangeCountry();
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.country',
        },
        {
          key: {
            parent: 'form',
            child: 'city',
          },
          rules: 'required',
          validationLabel: 'labels.city',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.cities,
              value: (item) => item.Id,
              text: 'Name',
            },
            returnObject: true,
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.city',
          onChange: (item) => {
            if (item?.State) {
              this.form.stateCode = item.State;
            } else {
              this.form.stateCode = '';
            }
          },
        },
        {
          key: {
            parent: 'form',
            child: 'AuthorizedPerson',
          },
          rules: authorizedUser,
          validationLabel: 'labels.authorizedPerson',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.authorizedPerson',
        },
        {
          key: {
            parent: 'form',
            child: 'EoriCode',
          },
          validationLabel: 'labels.EORICode',
          rules: 'numeric',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.EORICode',
        },
        {
          key: {
            parent: 'form',
            child: 'postalCode',
          },
          rules: 'required|min:4|max:9',
          validationLabel: 'labels.postalCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.postalCode',
        },
        {
          key: {
            parent: 'form',
            child: 'stateCode',
          },
          rules: this.isStateCodeRequired ? 'required|max:2' : '',
          validationLabel: 'labels.stateCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            autocomplete: 'new-password',
            isMagnify: true,
            magnifyURL:
              'https://www.ups.com/worldshiphelp/WSA/ENU/AppHelp/mergedProjects/CORE/Codes/State_Province_Codes.htm',
            magnifyLabel: 'buttons.showStateCodes',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.stateCode',
        },
        {
          key: {
            parent: 'form',
            child: 'contactPhone',
          },
          rules: rules.phone,
          validationLabel: 'labels.phone',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prefix: this.phonePrefix,
            autocomplete: 'new-password',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.phone',
        },
        {
          key: {
            parent: 'form',
            child: 'email',
          },
          validationLabel: 'labels.email',
          rules: 'required|email|max:50',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.email',
        },
      ];
    },
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    handleOpenURL() {
      window.open(
        'https://www.ups.com/worldshiphelp/WSA/ENU/AppHelp/mergedProjects/CORE/Codes/State_Province_Codes.htm',
        '_blank'
      );
    },
    onChangeCountry(countryId) {
      if (this.form.countryCode && countryId !== this.form.countryCode) {
        this.$store.state.loadingStatus = true;
        AddressService.getCounties(this.form.countryCode)
          .then((response) => {
            this.phonePrefix = response.data.Result[0].PhoneCode;
          })
          .catch(window.showError);

        AddressService.getCities(this.form.countryCode)
          .then((response) => {
            this.cities = response.data.Result;
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    getCountries() {
      this.$store.state.loadingStatus = true;
      AddressService.getCounties()
        .then((response) => {
          this.countries = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    changeItemsPerPage() {
      this.page = 1;
      this.getSubCompanies();
    },
    onPageChange(newPage) {
      this.$store.state.loadingStatus = true;
      this.page = newPage;
      this.getSubCompanies();
    },
    deleteItem(item) {
      this.itemToDelete = item;
      this.$refs.deleteModalRef.modalStatus = true;
    },

    confirmDelete() {
      this.$store.state.loadingStatus = true;
      CompanyServices.deleteCompany(this.itemToDelete.Id)
        .then((response) => {
          window.showSuccess(this.$t('messages.deleteAddressSuccess'));
          this.getSubCompanies();
          this.$refs.deleteModalRef.modalStatus = false;
        })
        .catch((error) => {
          window.showError(error);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
          this.showDeleteConfirmation = false;
          this.$refs.deleteModalRef.modalStatus = false;
        });
    },

    saveSubCompany() {
      this.$store.state.loadingStatus = true;
      this.$refs.observer.validate().then((success) => {
        if (success) {
          if (this.isEditing) {
            const body = {
              Id: this.form.Id,
              Name: this.form.companyName,
              Address: this.form.companyAddress,
              ContactPhone: clearText(this.form.contactPhone),
              CountryId: this.form.countryCode,
              CityId: this.form.city,
              PostalCode: this.form.postalCode,
              StateCode: this.form.stateCode,
              Mail: this.form.email,
              IsHomeAddress: this.isHome,
              EoriCode: this.form.EoriCode,
              AuthorizedPerson: this.form.AuthorizedPerson,
            };
            CompanyServices.updateCompany(body)
              .then((response) => {
                this.$store.state.loadingStatus = false;
                window.showSuccess(this.$t('messages.editAddressSuccess'));
                this.getSubCompanies();
                this.resetDialog();
              })
              .catch((error) => {
                this.$store.state.loadingStatus = false;
                window.showError(error);
                this.resetDialog();
              });
          } else {
            const body = {
              Name: this.form.companyName,
              Address: this.form.companyAddress,
              ContactPhone: clearText(this.form.contactPhone),
              CountryId: this.form.countryCode,
              CityId: this.form.city.Id,
              PostalCode: this.form.postalCode,
              StateCode: this.form.stateCode,
              Mail: this.form.email,
              IsHomeAddress: this.isHome,
              EoriCode: this.form.EoriCode,
              AuthorizedPerson: this.form.AuthorizedPerson,
            };

            CompanyServices.addSubCompany(body)
              .then((response) => {
                this.$store.state.loadingStatus = false;
                window.showSuccess(this.$t('messages.addAddressSuccess'));
                this.getSubCompanies();
                this.resetDialog();
              })
              .catch((error) => {
                this.$store.state.loadingStatus = false;
                window.showError(error);
                this.resetDialog();
              });
          }
        }
      });
    },

    editAddress(item) {
      this.form = {
        Id: item.Id,
        companyName: item.Name,
        companyAddress: item.Address,
        contactPhone: clearText(item.ContactPhone),
        countryCode: item.CountryId,
        city: item.CityId,
        postalCode: item.PostalCode,
        stateCode: item.StateCode,
        email: item.Mail,
        EoriCode: item.EoriCode,
        AuthorizedPerson: item.AuthorizedPerson,
      };
      (this.isHome = item.IsHomeAddress), (this.isEditing = true);
      this.dialog = true;

      this.$store.state.loadingStatus = true;

      AddressService.getCities(this.form.countryCode)
        .then((response) => {
          this.cities = response.data.Result;

          this.$store.state.loadingStatus = false;
        })
        .catch((error) => {
          window.showError(error);
          this.$store.state.loadingStatus = false;
        });
    },

    getSubCompanies() {
      this.$store.state.loadingStatus = true;
      CompanyServices.getSubCompanies(this.page, this.itemsPerPage)
        .then((response) => {
          this.$store.state.loadingStatus = false;
          this.subCompanies = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetDialog() {
      this.isEditing = false;
      this.dialog = false;
      this.form = {};
      this.isHome = false;
      this.$refs.observer.reset();
    },
  },
};
</script>
