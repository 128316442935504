import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'companies';
let RESOURCE_NAME2 = 'integration-ship';
export default {
  async getPortalUsers(
    CompanyName,
    PortalCustomerCode,
    PortalSellerCode,
    PortalCustomerType,
    page,
    pageSize
  ) {
    return axios.get(`${RESOURCE_NAME}/get-portals`, {
      params: {
        CompanyName,
        PortalCustomerCode,
        PortalSellerCode,
        PortalCustomerType,
        page,
        pageSize,
      },
    });
  },
  async getIntegrationServices(type) {
    return axios.get(`${RESOURCE_NAME2}/get-service-types/${type}`);
  },
  async getIntegrationPackages(type) {
    return axios.get(`${RESOURCE_NAME2}/get-pickup-types/${type}`);
  },
  async addPortalUser(body) {
    return axios.post(`${RESOURCE_NAME}/add-portal`, body);
  },

  /**
   * Deletes a portal company.
   * @param {any} params
   * @returns {Promise}
   */
  async deletePortalCompany(params) {
    return axios.delete(`${RESOURCE_NAME}/delete-portal`, { params });
  },

  /**
   * Update a portal company's status.
   * @param {{ IsActivated: boolean, CompanyId: string }} body
   * @returns {Promise}
   */
  async updatePortalCompanyStatus(body) {
    return axios.put(`${RESOURCE_NAME}/updatePortalActivation`, body);
  },
};
